
    .myClass-container {
        width: 100%;
        height: 100%;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .myclass-lists {
        padding-top: 30px;
        width: 1200px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0 auto;
        .class-panel {
            border-radius: 8px;
            width: 559px;
            overflow: hidden;
            margin-bottom: 54px;
            transition: margin 0.5s;
            &:nth-child(2n+1){
                margin-right: 80px;
            }
            &:hover {
                margin-top: -28px;
                .bottom-shadow2 {
                    margin-bottom: -15px;
                }
                .bottom-shadow3 {
                    margin-bottom: 0;
                }
				.course-cont-box {
					.cengji-box {
						.class-panel-detail {
							.detail-btn-area {
								.detail-start-btn {
									display: flex;
                                    cursor: pointer;
								}
								.detail-btn {
									display: none;
								}
							}
						}
					}
				}
            }
            .class-panel-tit {
                flex: 1;
                height: 96px;
                background-color: #005496;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                h4 {
                    font-size: 28px;
                    color: #FFFFFF;
                    font-weight: 500;
                    padding-left: 26px;
                }
            }
            &:nth-child(4n + 1) {
                .class-panel-tit {
                    background-color: #005496;
                }
            }
            &:nth-child(4n + 2) {
                .class-panel-tit {
                    background-color: #A8C95A;
                }
            }
            &:nth-child(4n + 3) {
                .class-panel-tit {
                    background-color: #F1B55B;
                }
            }
            &:nth-child(4n) {
                .class-panel-tit {
                    background-color: #F15B5C;
                }
            }
            .course-cont-box {
                position: relative;
                height: 187px;
                padding: 33px 31px 0;
                background: #fff;
                border-radius: 0 0 8px 8px;
                .cengji-box {
                    width: 100%;
                    height: auto;
                    position: relative;
                    z-index: 4;
                    .class-panel-content {
                        /*margin-top: 47px;*/
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        font-size: 16px;
                        padding: 24px 0 46px 0;
                        border-bottom: 1px solid #EAEAEA;
                        .data-unit {
                            /*margin-right: 50px;*/
                            .unit-txt {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                .unit-icon {
                                    width: 19px;
                                    height: 19px;
                                    background: url('../../assets/icons/sprite.png');
                                    margin-right: 9px;
                                    &.unit-member {
                                        background-position: -12px -5px;
                                    }
                                    &.unit-practice {
                                        background-position: -80px -5px;
                                    }
                                    &.unit-test {
                                        background-position: -46px -5px;
                                    }
                                    &.unit-homework {
                                        background-position: -114px -5px;
                                    }
                                }
                            }
                            .unit-num {
                                margin-top: 3px;
                                text-align: center;
                                font-size: 20px;
                                font-weight: bold;
                            }
                        }
                    }
                    .class-panel-detail {
						&.teacher-btns {
							justify-content: space-between;
							i.el-icon-menu {
								font-size: 16px;
								margin-right: 5px;
							}
						}
                        padding-right: 24px;
                        height: 62px;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        .detail-btn-area {
                            .detail-btn {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 30px;
                                height: 30px;
                                border-radius: 15px;
                                background-color: #0B30B5;
                                color: #FFF;
                                box-shadow: 4px 2px 4px #2937E4;
                            }
							.detail-start-btn {
								display: none;
								justify-content: center;
								align-items: center;
								width: 100px;
								height: 30px;
								border-radius: 15px;
								background-color: #0B30B5;
								color: #FFF;
								box-shadow: 4px 2px 4px #2937E4;

							}
                        }
                    }
                }
                .bottom-shadow1 {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: 3;
                    background: #fff;
                    box-shadow: 1px 7px 25px 0 rgba(90, 98, 123, 0.14);
                    border-radius: 0 0 8px 8px;
                }
                .bottom-shadow2 {
                    background: #ffffff;
                    box-shadow: 0 4px 22px 0 rgba(145, 151, 167, 0.13);
                    width: 530px;
                    height: 15px;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    margin-left: -266px;
                    z-index: 2;
                    border-radius: 0 0 8px 8px;
                    transition: margin 0.5s;
                }
                .bottom-shadow3 {
                    width: 100%;
                    height: 28px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    transition: margin 0.5s;
                    .shaodow3-cont {
                        width: 502px;
                        height: 28px;
                        background: #ffffff;
                        border-radius: 0 0 8px 8px;
                        position: absolute;
                        opacity: 0.93;
                        z-index: 1;
                        bottom: -28px;
                        left: 50%;
                        margin-left: -252.5px;
                        box-shadow: 0 4px 22px 0 rgba(145, 151, 167, 0.13);
                    }
                }
            }
        }
    }
    .choose-course-dialog {
        .dialog-title {
            display: flex;
            align-items: center;
            padding-right: 30px;
            .title {
                font-size: 18px;
                margin-right: 10px;
            }
            .subtitle {
                width: 1%;
                flex: 1;
            }
        }
        .el-dialog__body {
            border-top: 1px solid #EAEAEA;
            border-bottom: 1px solid #EAEAEA;
            padding: 0;
            .courseCascader {
                height: 300px;
                box-sizing: border-box;
                &.el-cascader-panel {
                    .el-scrollbar__wrap {
                        overflow-x: hidden !important;
                        height: 100%;

                        .el-scrollbar__view {
                            height: 100%;
                        }
                    }
                }
                ::v-deep .el-radio {
                    &.is-disabled {
                        & + .el-cascader-node__label {
                            color: #c0c4cc;
                        }
                        & + .el-cascader-node__label + .el-icon-arrow-right {
                            color: #c0c4cc;
                        }
                    }
                }
            }
            .course-content {
                display: flex;
                flex-wrap: wrap;
                margin: -20px -20px -20px -15px;
                max-height: 294px;
                overflow-y: auto;
                .course-item {
                    width: 140px;
                    height: 78px;
                    position: relative;
                    margin-left: 15px;
                    margin-top: 20px;
                    border-radius: 5px;
                    overflow: hidden;
                    cursor: pointer;
                    .course-cover {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #fdf6ef;
                        img {
                            max-height: 100%;
                            max-width: 100%;
                        }
                    }
                    .course-mask {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, .5);
                        justify-content: center;
                        align-items: center;
                        display: none;
                        .course-title {
                            margin: 0;
                            color: #fff;
                            padding: 0 10px;
                            font-size: 15px;
                        }
                        .iconfont {
                            position: absolute;
                            top: 0;
                            right: 0;
                            padding: 3px;
                            font-size: 18px;
                            color: #409EFF;
                            display: none;
                        }
                    }
                    &:hover {
                        .course-mask {
                            display: flex;
                        }
                    }
                    &.current {
                        .course-mask {
                            display: flex;
                            .iconfont {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

